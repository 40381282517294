<template>
  <v-select :clearable="false" :options="countries" :value="selected" @input="setSelected" class="mz_input_2"
    placeholder="Choose a country">
    <template slot="option" slot-scope="option">
      <span class="flag-icon flag-icon-squared mr-2" :class="['flag-icon-' + option.value.toLowerCase()]"></span>
      <span class="flag-text">{{ option.label }}</span>
    </template>
  </v-select>
</template>

<script>
import axiosInstance from "@/api";
export default {
  name: "CountrySelect",
  data() {
    return {
      selected: '',
      rawCountries: []
    }
  },
  created() {
    this.getCountryList();
  },
  computed: {
    countries() {
      return this.rawCountries.map(country => ({
        value: country.isoCode,
        label: country.name
      }))
    }
  },
  methods: {
    async getUserCountry() {
      try {
        const response = await fetch('https://ip2c.org/s');
        const data = await response.text();
        const countryCode = data.split(';')[1];
        await this.getCountryNameByCode(countryCode);
      } catch (error) {
        console.error(error)
      }
    },
    async getCountryList() {
      try {
        const {data} = await axiosInstance.get('/countries');
        this.rawCountries = data;
        await this.getUserCountry();
      } catch (error) {
        console.log(error)
      }
    },
    getCountryNameByCode(code) {
      const country = this.countries.find(c => c.value === code)
      if (country) {
        this.selected = country;
        this.$emit('changeCountry',country);
        return country;
      } else {
        return 'Unknown country'
      }
    },
    setSelected(value) {
      this.selected = value;
      this.$emit('changeCountry',value);
    }
  }
}
</script>

<style></style>