<template>
  <div class="mz__modal px-3" v-if="isOpen">
    <div class="mz__modal-backdrop"></div>
    <div class="mz__modal-content">
      <DashCard :noPadding="true">
        <div class="d-flex justify-content-end">
          <a
            href="javascript:void(0)"
            @click="handleClose"
            class="mz__modal-close"
          >
            <i class="bi bi-x-lg"></i>
          </a>
        </div>
        <div class="my-3 text-center px-4">
          <h3 class="mz__text--large text-is--primary-dark text-is--regular">
            Enter 2FA Code
          </h3>
          <p class="mz__text--small text-is--primary-grey text-is--regular">
            Check your phone for a 2FA Code or Open your Google Authenticator
            app
          </p>
        </div>
        <div class="px-4">
          <otp
            class="d-flex align-items-center justify-content-between w-100"
            ref="otpInput"
            input-classes="otp-input has--border border--lightgrey"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            input-type="password"
            :is-input-num="true"
            @on-change="handleOnChange"
            @on-complete="verifyPin"
          />
        </div>
        <ProgressLoader v-if="verifying" />
      </DashCard>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerifyTwoFa",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    handleClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      verifying: false,
    };
  },
  methods: {
    handleOnChange(value) {
      // console.log(value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    async verifyPin(val) {
      this.verifying = true;
      try {
        // await this.$store.dispatch('auth/verifyTwoFaPin',val);
        this.$emit("pinProvided", val);
      } catch (error) {
        console.log(error);
        this.handleClearInput();
        if (
          error.response &&
          error.response.data &&
          (error.response.data.msg || error.response.data.message)
        ) {
          this.$toast.error(
            error.response.data.message || error.response.data.message
          );
        } else {
          this.$toast.error("Network error");
        }
      } finally {
        this.verifying = false;
      }
    },
  },
};
</script>

<style></style>
