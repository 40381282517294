import axiosInstance from "@/api";
const actions = {
  async getBills({}) {
    try {
      const response = (await axiosInstance.get(`/bills`)).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createBill({}, payload) {
    try {
      const response = (await axiosInstance.post(`/bills`, payload)).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export default {
  namespaced: true,
  actions,
};
