import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import auth from "./modules/auth.js";
import transactions from "./modules/transactions.js";
import cards from "./modules/cards.js";
import bills from "./modules/bills.js";
import payment from "./modules/payment.js";
import contact from "./modules/contact.js";
import wallet from "./modules/wallet.js";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  modules: {
    auth,
    transactions,
    cards,
    bills,
    payment,
    contact,
    wallet,
  },
  state: {
    theme: "light__theme",
  },
  getters: {},
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
    },
  },
  actions: {
    async getCurrentCryptoPrice({ commit, state }, walletSymbol) {
      try {
        const response = await axios.get(
          `https://api.binance.com/api/v3/ticker/price?symbol=${walletSymbol.toUpperCase()}USDT`
        );
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(response.data.price);
          }, 1000);
        });
      } catch (error) {
        console.log(error);
        return new Promise((resolve, reject) => {
          reject("An error occurred");
        });
      }
    },
  },
});
