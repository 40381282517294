import Vue from "vue";
import VueRouter from "vue-router";
import { ROUTES } from "./routes";
import store from "@/store";

Vue.use(VueRouter);

const routes = ROUTES;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.auth.isAuthenticated) {
      next({
        name: "Login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
