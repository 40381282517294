import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import axiosInstance from "@/api";
const actions = {
  async getCards({}) {
    try {
      const response = (await axiosInstance.get(`/cards`)).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export default {
  namespaced: true,
  actions,
};
