import axiosInstance from "@/api";
const state = {
  user: null,
  isAuthenticated: false,
};

const getters = {
  user: (state) => state.user,
  isAuthenticated: (state) => state.isAuthenticated,
};

const actions = {
  async login({ commit }, credentials) {
    try {
      const response = await axiosInstance.post(`/auth/login`, credentials);
      if (response.status && response.status == 202) {
        return response;
      }
      commit("setUser", response.data.data);
      commit("setAuthenticated", true);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async requestPasswordReset({ commit }, field) {
    try {
      await axiosInstance.post(`/auth/password/reset`, {
        field: parseInt(field),
      });
    } catch (error) {
      throw error;
    }
  },
  async phoneOtp({ commit }, phoneNumber) {
    try {
      await axiosInstance.post(`/auth/phone/send`, {
        phoneNumber,
      });
    } catch (error) {
      throw error;
    }
  },
  async resendPhoneOtp({ commit }, phoneNumber) {
    try {
      await axiosInstance.post(`/auth/resend`, {
        phoneNumber,
      });
    } catch (error) {
      throw error;
    }
  },
  async verifyOtp({ commit }, code) {
    try {
      await axiosInstance.post(`/auth/verify`, {
        code,
      });
    } catch (error) {
      throw error;
    }
  },
  async verifyPasswordOtp({ commit }, code) {
    try {
      const response = await axiosInstance.post(`/auth/password/verify`, {
        code,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async changePassword({ commit }, credentials) {
    try {
      const response = await axiosInstance.post(
        `/auth/password/change`,
        credentials
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async setPin({ commit, state }, pin) {
    try {
      await axiosInstance.post(`/account/pin/set`, {
        pin,
      });
    } catch (error) {
      throw error;
    }
  },
  async register({ commit }, credentials) {
    console.log(credentials);
    try {
      const response = await axiosInstance.post(`/auth/register`, credentials);
      commit("setUser", response.data.data);
      commit("setAuthenticated", true);
    } catch (error) {
      throw error;
    }
  },
  async logout({ commit, state }) {
    try {
      await axiosInstance.post(`/auth/logout`);
      commit("setAuthenticated", false);
      setTimeout(() => {
        commit("setUser", null);
      }, 5000);
    } catch (error) {
      throw error;
    }
  },
  async updateUserInfo({ commit, state }) {
    try {
      const response = await axiosInstance.get(`/user`);
      commit("UPDATE_USER_INFO", response.data.data);
    } catch (error) {
      throw error;
    }
  },
  async getAccount({ commit, state }) {
    try {
      const response = await axiosInstance.get(`/account`);
      commit("UPDATE_USER_ACCOUNT", response.data.data);
    } catch (error) {
      throw error;
    }
  },
  async verifyAccountPin({ commit }, pin) {
    try {
      await axiosInstance.post(`/account/pin/verify`, {
        pin,
      });
      return true;
    } catch (error) {
      throw error;
    }
  },
  async changeAccountPassword({ commit }, payload) {
    try {
      await axiosInstance.post(`/user/password/change`, payload);
      return true;
    } catch (error) {
      throw error;
    }
  },
  async changeAccountPin({ commit }, payload) {
    try {
      await axiosInstance.post(`/account/pin/change`, payload);
      return true;
    } catch (error) {
      throw error;
    }
  },
  async submitKycDoc({ commit }, payload) {
    try {
      await axiosInstance.post(`/account/kyc/verify`, payload);
      return true;
    } catch (error) {
      throw error;
    }
  },
  async getTwoFaSettings({}) {
    try {
      const response = await axiosInstance.get(`/2fa`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateTwoFa({}, payload) {
    try {
      const response = await axiosInstance.put(`/2fa`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async generateGAuthQr({}) {
    try {
      const response = await axiosInstance.post(`/2fa`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async verifyTwoFaPin({}, pin) {
    try {
      const response = await axiosInstance.post(`/2fa/verify`, {
        otpCode: pin,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  UPDATE_USER_INFO(state, user) {
    state.user.user = user;
  },
  UPDATE_USER_ACCOUNT(state, account) {
    state.user.account = account;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
