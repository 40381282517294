import axiosInstance from "@/api";
const actions = {
  async getUserWallets({}) {
    try {
      const response = (await axiosInstance.get(`/wallets`)).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async marketData({}, coinSymbol) {
    try {
      const response = (await axiosInstance.get(`/klines/${coinSymbol}`)).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async toggleFavourite({}, payload) {
    try {
      await axiosInstance.put(`/wallets/${payload.id}`, {
        isFavourite: payload.isFavourite,
      });
    } catch (error) {
      throw error;
    }
  },
  async getSingleWallet({}, id) {
    try {
      const response = (await axiosInstance.get(`/wallets/${id}`)).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getSendFee({}, payload) {
    try {
      const response = (
        await axiosInstance.post(`/wallet/fee`, {
          chain: payload.chain,
          amount: payload.amount,
          recipientAddress: payload.recipientAddress,
        })
      ).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getTransactions({}, address) {
    try {
      const response = (
        await axiosInstance.get(`/wallet/${address}/transactions`)
      ).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async initiateTrade({}, payload) {
    try {
      const response = (await axiosInstance.post(`/wallet/trade`, payload))
        .data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async sendCoin({}, payload) {
    try {
      const response = await axiosInstance.post(`/wallet/send`, payload);
      if (response.status && response.status == 202) {
        return response;
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
export default {
  namespaced: true,
  actions,
};
