<template>
  <div class="mz__modal px-3" v-if="isOpen">
    <div class="mz__modal-backdrop"></div>
    <div class="mz__modal-content">
      <DashCard :noPadding="true">
        <div class="d-flex justify-content-end">
          <a
            href="javascript:void(0)"
            @click="handleClose"
            class="mz__modal-close"
          >
            <i class="bi bi-x-lg"></i>
          </a>
        </div>
        <div class="my-3 text-center px-4">
          <span class="mz__text--large text-is--primary-dark">
            Enter your account pin
          </span>
        </div>
        <div class="px-4">
          <otp
            class="d-flex align-items-center justify-content-between w-100"
            ref="otpInput"
            input-classes="otp-input has--border border--lightgrey"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            input-type="password"
            :is-input-num="true"
            @on-change="handleOnChange"
            @on-complete="verifyPin"
          />
        </div>
        <div class="mt-5 text-center">
          <span class="mz__text--small text-danger" v-if="pinInvalid">
            {{ errorMessage }}
          </span>
          <ProgressLoader v-if="verifying" />
        </div>
      </DashCard>
    </div>
  </div>
</template>

<script>
import ProgressLoader from "./ProgressLoader";
export default {
  name: "VerifyPin",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    handleClose: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    ProgressLoader,
  },
  data() {
    return {
      verifying: false,
      pinInvalid: false,
      errorMessage: "You have entered an incorrect pin",
    };
  },
  methods: {
    handleOnChange(value) {
      // console.log(value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    async verifyPin(val) {
      this.pinInvalid = false;
      this.verifying = true;
      try {
        await this.$store.dispatch("auth/verifyAccountPin", val);
        this.$emit("pinVerified", val);
        this.handleClose();
      } catch (error) {
        console.log(error);
        this.handleClearInput();
        if (
          error.response &&
          error.response.data &&
          (error.response.data.msg || error.response.data.message)
        ) {
          this.errorMessage = error.response.data.msg;
          this.pinInvalid = true;
        } else {
          this.$toast.error("Network error");
        }
      } finally {
        this.verifying = false;
      }
    },
  },
};
</script>

<style></style>
