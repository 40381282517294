<template>
 <div class="button-loader mx-2"></div>
</template>

<script>
export default {
 name: "ButtonSpinner"
}
</script>

<style scoped>
.button-loader {
 border: 2px solid transparent;
 border-top: 2px solid #ffffff;
 border-radius: 50%;
 width: 18px;
 height: 18px;
 animation: spin 2s linear infinite;
}

@keyframes spin {
 0% {
  transform: rotate(0deg);
 }

 100% {
  transform: rotate(360deg);
 }
}
</style>