import Vue from "vue";
import VueMeta from "vue-meta";
import Vuelidate from "vuelidate";
import Toast from "vue-toastification";
import DashCard from "@/components/Dashboard/DashCard.vue";
import BackButton from "@/components/BackButton.vue";
import ButtonSpinner from "@/components/ButtonSpinner.vue";
import Countdown from "@/components/Countdown.vue";
import SectionLoader from "@/components/Dashboard/SectionLoader.vue";
import VerifyPin from "@/components/Dashboard/VerifyPin.vue";
import ConfirmModal from "@/components/Dashboard/ConfirmModal.vue";
import vSelect from "vue-select";
import CountrySelect from "@/components/LocationManagement/CountrySelect";
import StateSelect from "@/components/LocationManagement/StateSelect";
import PhoneCodeSelect from "@/components/LocationManagement/PhoneCodeSelect";
import ProgressLoader from "@/components/Dashboard/ProgressLoader";
import VerifyTwoFa from "@/components/Auth/VerifyTwoFa";

// Date Picker Component
import { Datetime } from "vue-datetime";
// import Select2Component
import Select2 from "v-select2-component";
Vue.component("mz-select", Select2);
Vue.component("v-select", vSelect);
// OTP Input
import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("otp", OtpInput);
// Country Code Picker Component
import VueCountryCode from "vue-country-code";
Vue.component("country-code", VueCountryCode);
Vue.component("DashCard", DashCard);
Vue.component("SectionLoader", SectionLoader);
Vue.component("BackButton", BackButton);
Vue.component("ButtonSpinner", ButtonSpinner);
Vue.component("CountrySelect", CountrySelect);
Vue.component("StateSelect", StateSelect);
Vue.component("PhoneCodeSelect", PhoneCodeSelect);
Vue.component("Countdown", Countdown);
Vue.component("VerifyPin", VerifyPin);
Vue.component("ProgressLoader", ProgressLoader);
Vue.component("ConfirmModal", ConfirmModal);
Vue.component("VerifyTwoFa", VerifyTwoFa);

const ToastOptions = {
  timeout: 8000,
  position: "top-center",
  transition: "Vue-Toastification__bounce",
};
Vue.use(Toast, ToastOptions);
Vue.component("datetime", Datetime);
Vue.use(Vuelidate);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
