<template>
 <span class="mz__text--small text-is--primary-dark">({{ time }}s)</span>
</template>

<script>
export default {
 name: "Countdown",
 props: ["minutes","seconds"],
 data() {
  return {
   time: 0,
  };
 },
 methods: {
  countdown(minutes,seconds) {
   var endTime,hours,mins,msLeft,time;

   const twoDigits = (n) => {
    return n <= 9 ? "0" + n : n;
   };

   const updateTimer = () => {
    msLeft = endTime - +new Date();
    if (msLeft < 1000) {
     this.time = "Time is up!";
    } else {
     time = new Date(msLeft);
     hours = time.getUTCHours();
     mins = time.getUTCMinutes();
     this.time =
      (hours ? hours + ":" + twoDigits(mins) : mins) +
      ":" +
      twoDigits(time.getUTCSeconds());
     setTimeout(updateTimer,time.getUTCMilliseconds() + 500);
    }
   };
   endTime = +new Date() + 1000 * (60 * minutes + seconds) + 500;
   updateTimer();
  },
  start() {
   this.countdown(this.minutes,this.seconds);
   console.log(this.minutes,this.seconds);
  },
  reset() {
   this.time = 0;
  },
  countdownEnded() {
   if (this.time === "Time is up!") {
    return true;
   } else {
    return false;
   }
  },
 },
 watch: {
  time: function (newVal,oldVal) {
   if (this.countdownEnded()) {
    this.$emit("countdownEnded");
   }
  },
 },
};
</script>

<style>

</style>
