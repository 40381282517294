<template>
 <div class="d-flex flex-column align-items-center" :class="{
  'justify-content-start': position == 'left',
  'justify-content-center': position == 'center',
  'justify-content-end': position == 'right',
 }">
  <div class="mz-section-loader"></div>
  <div class="text-center mz__text--small text-is--primary-grey my-3" v-if="position == 'center' && message != ''
  ">
   {{ message }}
  </div>
 </div>
</template>

<script>
export default {
 name: "SectionLoader",
 props: {
  position: {
   type: String,
   default: "center"
  },
  message: {
   type: String,
   default: ""
  },
 }
}
</script>

<style scoped>
.mz-section-loader {
 border: 2px solid #f3f3f3;
 border-top: 2px solid #3498db;
 border-radius: 50%;
 width: 40px;
 height: 40px;
 animation: spin 2s linear infinite;
}

@keyframes spin {
 0% {
  transform: rotate(0deg);
 }

 100% {
  transform: rotate(360deg);
 }
}
</style>