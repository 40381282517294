import axiosInstance from "@/api";
const actions = {
  async createEmailContact({}, email) {
    try {
      const response = (
        await axiosInstance.post(`/contacts`, {
          email,
        })
      ).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createCryptoContact({}, payload) {
    try {
      const response = (await axiosInstance.post(`/addresses`, payload)).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async deleteEmailContact({}, id) {
    try {
      await axiosInstance.delete(`/contacts/${id}`);
    } catch (error) {
      throw error;
    }
  },
  async deleteCryptoContact({}, id) {
    try {
      await axiosInstance.delete(`/addresses/${id}`);
    } catch (error) {
      throw error;
    }
  },
  async updateCryptoContact({}, payload) {
    try {
      await axiosInstance.put(`/addresses/${payload.id}`, {
        name: payload.name,
        cryptoAddress: payload.cryptoAddress,
      });
    } catch (error) {
      throw error;
    }
  },
  async getEmailContacts({}) {
    try {
      const response = (await axiosInstance.get(`/contacts`)).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getCryptoContacts({}) {
    try {
      const response = (await axiosInstance.get(`/addresses`)).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export default {
  namespaced: true,
  actions,
};
