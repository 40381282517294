import axiosInstance from "@/api";
const actions = {
  async request({}, payload) {
    try {
      const response = (await axiosInstance.post(`/payment-requests`, payload))
        .data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async generateInvoiceNumber({}) {
    try {
      const response = (await axiosInstance.get("/invoice/generate")).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createInvoice({}, payload) {
    try {
      const response = (await axiosInstance.post("/invoices", payload)).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async transfer({}, payload) {
    try {
      const response = await axiosInstance.post(
        "/account/transfer/morizon",
        payload
      );
      if (response.status && response.status == 202) {
        return response;
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async paySalaries({}, payload) {
    try {
      const response = await axiosInstance.post(
        "/account/salaries/pay",
        payload
      );
      if (response.status && response.status == 202) {
        return response;
      }
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export default {
  namespaced: true,
  actions,
};
