import axiosInstance from "@/api";
const actions = {
  async getTransactions({}) {
    try {
      const response = (await axiosInstance.get(`/transactions`)).data;
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export default {
  namespaced: true,
  actions,
};
