<template>
 <div class="mz__modal px-3" v-if="isOpen">
  <div class="mz__modal-backdrop" @click="handleClose"></div>
  <div class="mz__modal-content">
   <DashCard>
    <div class="w-100">
     <div class="text-center mb-4">
      <h4 class="text-is--primary-grey text-is--medium mz__subtitle mb-0">
       Confirmation
      </h4>
      <div class="my-4">
       <h4 class="text-is--primary-grey text-is--regular mz__text--large mb-0">
        {{ description }}
       </h4>
      </div>
     </div>
     <div class="col-lg-12 d-flex align-items-center justify-content-center mt-2">
      <div class="mr-2">
       <a href="javascript:void(0)" class="text-danger text-decoration-none text-is--medium mz__text--large"
        @click="cancelAction">Cancel</a>
      </div>
      <div class="">
       <a href="javascript:void(0)" class="text-success text-decoration-none text-is--medium mz__text--large"
        @click="confirmAction">Yes</a>
      </div>
     </div>
    </div>
   </DashCard>
  </div>
 </div>
</template>

<script>
export default {
 name: "ConfirmModal",
 props: {
  isOpen: {
   type: Boolean,
   default: false
  },
  handleClose: {
   type: Function,
   default: () => {}
  },
  description: {
   type: String,
   required: true
  }
 },
 methods: {
  cancelAction() {
   this.handleClose();
  },
  async confirmAction() {
   await this.$emit('actionConfirmed');
   this.handleClose();
  }
 }
}
</script>

<style>

</style>