<template>
 <div id="progressLoader">
  <div class="c-1"></div>
  <div class="c-2"></div>
  <div class="c-3"></div>
  <div class="c-4"></div>
  <div class="c-5"></div>
  <div class="c-6"></div>
  <div class="c-7"></div>
  <div class="c-8"></div>
 </div>
</template>

<script>
export default {
 name: "ProgressLoader"
}
</script>

<style scoped>
#progressLoader {
 position: relative;
 padding: 0;
 height: 5px;
 width: 100%;
}

#progressLoader div {
 width: 0;
 height: 3px;
 position: absolute;
 display: inline;
 left: 50%;
 -webkit-animation: radial 7s infinite linear;
 animation: radial 7s infinite linear;
}

#progressLoader .c-1 {
 z-index: 2;
 background: transparent linear-gradient(321deg, #0d1cb3 0%, #9f57e1 100%) 0% 0% no-repeat padding-box;
 -webkit-animation-delay: -2s;
 animation-delay: -2s;
}

#progressLoader .c-2 {
 z-index: 1;
 background: radial-gradient(circle at 75% 88%,
   rgba(62, 185, 229, 1) 0%,
   rgba(13, 28, 179, 1) 58%,
   rgba(13, 28, 179, 1) 100%);
 -webkit-animation-delay: -1s;
 animation-delay: -1s;
}

#progressLoader .c-3 {
 z-index: 0;
 background: radial-gradient(circle at 93% 88%,
   rgba(255, 195, 0, 1) 0%,
   rgba(179, 13, 13, 1) 50%,
   rgba(179, 13, 13, 1) 100%);
}

#progressLoader .c-4 {
 z-index: 0;
 background: radial-gradient(circle at 68% 100%,
   rgba(159, 87, 225, 1) 30%,
   rgba(159, 87, 225, 1) 49%,
   rgba(124, 59, 184, 1) 100%);
 -webkit-animation-delay: 1s;
 animation-delay: 1s;
}

#progressLoader .c-5 {
 z-index: 0;
 background: rgba(13, 28, 179, 1) radial-gradient(circle at 66% 80%,
   rgba(62, 185, 229, 1) 0%,
   rgba(13, 28, 179, 1) 100%,
   rgba(13, 28, 179, 1) 100%);
 -webkit-animation-delay: 2s;
 animation-delay: 2s;
}

#progressLoader .c-6 {
 z-index: 0;
 background: radial-gradient(circle at 66% 98%,
   rgba(213, 227, 0, 1) 0%,
   rgba(7, 206, 41, 1) 100%,
   rgba(7, 206, 41, 1) 100%);
 -webkit-animation-delay: 3s;
 animation-delay: 3s;
}

#progressLoader .c-7 {
 z-index: 0;
 background: transparent radial-gradient(100% 70%, #3eb9e5 0%, #0d1cb3 90%) 0% 0% no-repeat padding-box;
 -webkit-animation-delay: 4s;
 animation-delay: 4s;
}

#progressLoader .c-8 {
 z-index: 0;
 background: transparent radial-gradient(100% 70%, #0ba9e2 0%, #b38a0d 90%) 0% 0% no-repeat padding-box;
 -webkit-animation-delay: 5s;
 animation-delay: 5s;
}

@-webkit-keyframes radial {
 from {
  left: 50%;
  width: 0;
  z-index: 100;
 }

 25% {
  left: 0;
  width: 100%;
  z-index: 10;
 }

 to {
  left: 0;
  width: 100%;
 }
}

@keyframes radial {
 from {
  left: 50%;
  width: 0;
  z-index: 100;
 }

 25% {
  left: 0;
  width: 100%;
  z-index: 10;
 }

 to {
  left: 0;
  width: 100%;
 }
}
</style>