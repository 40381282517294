<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "Morizon",
    titleTemplate: "%s - Dashboard",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  mounted() {
    if (this.theme == "light__theme") {
      document.body.classList.add("light__theme");
    } else {
      document.body.classList.add("dark__theme");
    }
  },
};
</script>
