<template>
 <div class="mb-5">
  <button class="back-button bg--fullWhite" @click="goBack">
   <img :src="BackIcon" alt="">
  </button>
 </div>
</template>

<script>
import BackIconDark from '@/assets/icons/BackIcon__dark.svg';
import BackIconLight from '@/assets/icons/BackIcon__light.svg';
export default {
 name: "BackButton",
 data() {
  return {
   BackIcon: ''
  }
 },
 mounted() {
  if (this.theme == 'dark__theme') {
   this.BackIcon = BackIconLight
  } else {
   this.BackIcon = BackIconDark
  }
 },
 methods: {
  goBack() {
   this.$router.go(-1);
  }
 },
 watch: {
  theme(val) {
   if (val == 'dark__theme') {
    this.BackIcon = BackIconLight
   } else {
    this.BackIcon = BackIconDark
   }
  }
 }
}
</script>

<style scoped>
.back-button {
 width: 60px;
 height: 60px;
 border-radius: 50%;
 box-shadow: var(--mz_shadow_2);
 border: none;
}

.back-button img {
 width: 28px;
}
</style>