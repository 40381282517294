<template>
 <div class="dash-card bg--fullWhite dash-card--alt small--radius has--shadow  mx-auto" :class="{
  'px-0': noPadding
 }">
  <div class="w-100">
   <slot></slot>
  </div>
 </div>
</template>

<script>
export default {
 name: "DashCard",
 props: {
  noPadding: {
   type: Boolean,
   default: false
  }
 }
}
</script>

<style>

</style>