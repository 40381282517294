<template>
 <v-select :clearable="false" :options="states" :value="selected" @input="setSelected" class="mz_input_2"
  placeholder="Choose a state">
  <template slot="option" slot-scope="option">
   <span class="flag-text">{{ option.label }}</span>
  </template>
 </v-select>
</template>

<script>
import axiosInstance from "@/api";
export default {
 name: "StateSelect",
 data() {
  return {
   rawStates: [],
   selected: ''
  }
 },
 computed: {
  states() {
   return this.rawStates.map(state => ({
    value: state.isoCode,
    label: state.name
   }))
  }
 },
 methods: {
  async getStates(countryCode) {
   this.rawStates = [];
   try {
    const {data} = await axiosInstance.get(`/countryStates?countryCode=${countryCode}`);
    if (data && data.length > 0) {
     this.rawStates = data;
    } else {
     console.log('No states found');
    }
   } catch (error) {
    console.log(error)
   }
  },
  setSelected(value) {
   this.selected = value;
   this.$emit('changedState',value);
  }
 }
}
</script>

<style></style>