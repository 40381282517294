import Vue from "vue";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
var isToday = require("dayjs/plugin/isToday");
var isYesterday = require("dayjs/plugin/isYesterday");
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(LocalizedFormat);
// Vuex
import { mapState, mapGetters, mapActions } from "vuex";
Vue.mixin({
  computed: {
    ...mapState(["theme", "auth"]),
    AccountInfo() {
      return this.auth.user;
    },
    currentYear() {
      return dayjs().format("YYYY");
    },
  },
  methods: {
    ...mapActions(["getCurrentCryptoPrice", "switchWebTheme"]),
    truncateCardNumber(cardNumber) {
      const hiddenNumber = cardNumber.replace(/.(?=.{4})/g, "*");
      const number = hiddenNumber.match(/.{1,4}/g);
      const formatted = number.join(" ");
      return formatted;
    },
    formattedAsCC(num) {
      const number = num.match(/.{1,4}/g);
      const formatted = number.join(" ");
      return formatted;
    },
    clearString(str) {
      return str.replace(/[^\w\s]/gi, "").replace(/\s/g, "");
    },
    formatCurrency(amount, currencyType = "GBP") {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currencyType,
        maximumFractionDigits: 8,
      });
      return formatter.format(amount);
    },
  },
  filters: {
    formatDateOnly(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    formatTimeOnly(date) {
      return dayjs(date).format("LT");
    },
    formatDateAndTime(date) {
      return dayjs(date).format("DD, MMM, YY - LT");
    },
    formatToCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    shortenAddressAtCenter(address) {
      return (
        address.substring(0, 6) +
        ".........." +
        address.substring(address.length - 6)
      );
    },
    formatAsBalance(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
});
