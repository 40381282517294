import Vue from "vue";
Vue.directive("numeric", {
  bind(el) {
    const numericOnlyRegex = /^([0-9]*[.]?[0-9]*)$/;
    el.addEventListener("input", function (event) {
      const currentValue = event.target.value;
      const numericValue = currentValue.match(numericOnlyRegex);
      event.target.value = numericValue ? numericValue[0] : "";
    });
    el.addEventListener("blur", function (event) {
      let currentValue = event.target.value;
      const numericValue = currentValue.match(numericOnlyRegex);
      event.target.value = numericValue ? numericValue[0] : "";
      if (!event.target.value.trim()) {
        event.target.value = "";
      }
    });
  },
});
