export const ROUTES = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/Auth/Login.vue"),
  },
  {
    path: "/auth/sign-up",
    name: "SignUp",
    component: () => import("@/views/Auth/SignUp.vue"),
  },
  {
    path: "/auth/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/Auth/ForgotPassword.vue"),
  },
  {
    path: "/dashboard",
    component: () => import("@/views/Dashboard/Index.vue"),
    name: "DashboardWrapper",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        component: () => import("@/views/Dashboard/Overview.vue"),
        children: [
          {
            path: "/dashboard",
            name: "Dashboard",
            component: () =>
              import("@/views/Dashboard/Overview/OverviewIndex.vue"),
          },
          {
            path: "/dashboard/create-gold-card",
            name: "CreateGoldCard",
            component: () =>
              import("@/views/Dashboard/Overview/CreateGoldCard.vue"),
          },
        ],
      },
      {
        path: "/dashboard/activity",
        name: "Activity",
        component: () => import("@/views/Dashboard/Activity.vue"),
      },
      {
        path: "/dashboard/transfer",
        component: () => import("@/views/Dashboard/Transfer.vue"),
        children: [
          {
            path: "/dashboard/transfer",
            name: "Transfer",
            component: () => import("@/views/Dashboard/Transfer/Index.vue"),
          },
          {
            path: "/dashboard/transfer/withdraw",
            name: "WithdrawCardBalance",
            component: () =>
              import("@/views/Dashboard/Transfer/WithdrawCardBalance.vue"),
          },
          {
            path: "/dashboard/transfer/deposit",
            name: "TransferDeposit",
            component: () =>
              import("@/views/Dashboard/Transfer/TransferDeposit.vue"),
          },
          {
            path: "/dashboard/transfer/receive",
            name: "ReceiveTransfer",
            component: () =>
              import("@/views/Dashboard/Transfer/TransferReceive.vue"),
          },
          {
            path: "/dashboard/transfer/send",
            name: "SendFunds",
            component: () =>
              import("@/views/Dashboard/Transfer/TransferSend.vue"),
          },
        ],
      },
      {
        path: "/dashboard/wallets",
        component: () => import("@/views/Dashboard/Wallets.vue"),
        children: [
          {
            name: "Wallets",
            path: "/dashboard/wallets",
            component: () =>
              import("@/views/Dashboard/Wallets/WalletsIndex.vue"),
          },
          {
            name: "SendCrypto",
            path: "/dashboard/wallets/send/:coin",
            component: () => import("@/views/Dashboard/Wallets/WalletSend.vue"),
          },
        ],
      },
      {
        path: "/dashboard/cards",
        component: () => import("@/views/Dashboard/Cards.vue"),
        children: [
          {
            path: "/dashboard/cards",
            name: "Cards",
            component: () => import("@/views/Dashboard/Cards/CardsIndex.vue"),
          },
          {
            path: "/dashboard/cards/:id",
            name: "ExpenseCardDetail",
            component: () =>
              import("@/views/Dashboard/Cards/ExpenseCardDetail.vue"),
          },
        ],
      },
      {
        path: "/dashboard/notifications",
        name: "Notifications",
        component: () => import("@/views/Dashboard/Notifications.vue"),
      },
      {
        path: "/dashboard/payment",
        component: () => import("@/views/Dashboard/Payment.vue"),
        children: [
          {
            path: "/dashboard/payment",
            name: "Payment",
            component: () =>
              import("@/views/Dashboard/Payment/PaymentIndex.vue"),
          },
          {
            path: "/dashboard/payment/morizon-account",
            name: "PayMorizonAccount",
            component: () =>
              import("@/views/Dashboard/Payment/PayMorizonAccount.vue"),
          },
          {
            path: "/dashboard/payment/pay-vat",
            name: "PayVat",
            component: () => import("@/views/Dashboard/Payment/PayVat.vue"),
          },
          {
            path: "/dashboard/payment/salaries",
            name: "PaySalaries",
            component: () =>
              import("@/views/Dashboard/Payment/PaySalaries.vue"),
          },
          {
            path: "/dashboard/payment/bills",
            name: "BillPayments",
            component: () =>
              import("@/views/Dashboard/Payment/BillPayments.vue"),
          },
          {
            path: "/dashboard/payment/request",
            name: "RequestPayment",
            component: () =>
              import("@/views/Dashboard/Payment/RequestPayment.vue"),
          },
          {
            path: "/dashboard/payment/create-invoice",
            name: "CreateInvoice",
            component: () =>
              import("@/views/Dashboard/Payment/CreateInvoice.vue"),
          },
          {
            path: "/dashboard/payment/bills/create",
            name: "CreateBill",
            component: () => import("@/views/Dashboard/Payment/CreateBill.vue"),
          },
        ],
      },
      {
        path: "/dashboard/money-management",
        component: () => import("@/views/Dashboard/MoneyManagement.vue"),
        children: [
          {
            path: "/dashboard/money-management",
            name: "MoneyManagement",
            component: () =>
              import(
                "@/views/Dashboard/MoneyManagement/MoneyManagementIndex.vue"
              ),
          },
          {
            path: "/dashboard/money-management/escrow",
            name: "ConnectEscrowAccount",
            component: () =>
              import(
                "@/views/Dashboard/MoneyManagement/ConnectEscrowAccount.vue"
              ),
          },
          {
            path: "/dashboard/money-management/pots",
            name: "MoneyManagementPots",
            component: () =>
              import(
                "@/views/Dashboard/MoneyManagement/MoneyManagementPots.vue"
              ),
          },
        ],
      },
      {
        path: "/dashboard/verification/kyc",
        name: "Kyc",
        component: () => import("@/views/Dashboard/UserSettings/Kyc.vue"),
      },
      {
        path: "/dashboard/settings/account",
        name: "UserSettings",
        component: () => import("@/views/Dashboard/UserSettings/Index.vue"),
        children: [
          {
            path: "/dashboard/settings/account",
            name: "AccountInfo",
            component: () =>
              import("@/views/Dashboard/UserSettings/AccountInfo.vue"),
          },
          {
            path: "/dashboard/settings/twofa",
            name: "TwoFa",
            component: () => import("@/views/Dashboard/UserSettings/TwoFa.vue"),
          },
          {
            path: "/dashboard/settings/customer-service",
            name: "CustomerService",
            component: () =>
              import("@/views/Dashboard/UserSettings/CustomerService.vue"),
          },
          {
            path: "/dashboard/settings/change-password",
            name: "ChangePassword",
            component: () =>
              import("@/views/Dashboard/UserSettings/ChangePassword.vue"),
          },
          {
            path: "/dashboard/settings/change-pin",
            name: "ChangePin",
            component: () =>
              import("@/views/Dashboard/UserSettings/ChangePin.vue"),
          },
          {
            path: "/dashboard/settings/address-book",
            name: "AddressBook",
            component: () =>
              import("@/views/Dashboard/UserSettings/AddressBook.vue"),
          },
        ],
      },
      {
        path: "/dashboard/account-upgrade",
        name: "AccountUpgrade",
        component: () =>
          import("@/views/Dashboard/UserSettings/AccountUpgrade.vue"),
      },
      // Handle 404
      {
        path: "*",
        redirect: "/dashboard",
      },
    ],
  },
  // Handle 404
  {
    path: "*",
    redirect: "/",
  },
];
